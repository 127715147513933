<template>
  <div class="bgcard">
    <div class="headerMsg">
      <button class="addbtn" @click="adduser" style="white-space: nowrap;">
        <i class="el-icon-setting" style="font-size: 0.22rem"></i>
        <span>{{ $t("lang.Alarmsetting") }}</span>
      </button>
      <div class="searchlist">
        <div class="select_b" style="margin-right: 0.2rem; margin-bottom: 0.1rem">
          <span style="color: #bbbbbb">{{ $t("lang.AlarmType") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.1rem"
            v-model="value"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="types"
          >
            <el-option :label="$t('lang.whole')" :value="''"></el-option>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select_b" style="margin-right: 0.2rem; margin-bottom: 0.1rem">
          <span style="color: #bbbbbb">{{ $t("lang.Alarmlevel") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.1rem"
            v-model="value1"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="grades($event, 1)"
          >
            <el-option :label="$t('lang.whole')" :value="''"></el-option>
            <el-option :label="$t('lang.Generalalarm')" :value="1"></el-option>
            <el-option :label="$t('lang.majoralarm')" :value="2"></el-option>
            <el-option
              :label="$t('lang.Emergencyalarm')"
              :value="3"
            ></el-option>
          </el-select>
        </div>
        <div class="select_b" style="margin-right: 0.2rem; margin-bottom: 0.1rem">
          <span style="color: #bbbbbb">{{ $t("lang.alarmstatus") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.1rem"
            v-model="value1_a"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="grades($event, 2)"
          >
            <el-option :label="$t('lang.whole')" :value="''"></el-option>
            <el-option :label="$t('lang.Recovered')" :value="1"></el-option>
            <el-option :label="$t('lang.alarmli')" :value="2"></el-option>
          </el-select>
        </div>
        <div class="searchtime" style="margin-right: 0.2rem; margin-bottom: 0.1rem">
          <span style="color: #bbbbbb">{{ $t("lang.Alarmtime") }}：</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            :range-separator="$t('lang.to')"
            :start-placeholder="$t('lang.Startdate')"
            :end-placeholder="$t('lang.Enddate')"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            @change="picker"
          >
          </el-date-picker>
        </div>
        <div class="query" style="margin-bottom: 0.1rem">
          <div class="inputname" style="margin-right: 0.2rem;">
            <input
              style="width: 2.2rem; font-size: 0.15rem"
              v-model="value2"
              :placeholder="$t('lang.servernameorsn')"
            />
          </div>
          <div class="searchbtn">
            <button @click="search">
              <p>{{ $t("lang.search") }}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column prop="name"  :label="$t('lang.name')" align="center">
        </el-table-column>
        <el-table-column :label="$t('lang.type')" align="center">
          <template #default="scope">
            <span v-if="scope.row.type == 3">{{
              $t("lang.Serverofflinealarm")
            }}</span>
            <span v-if="scope.row.type == 4">{{
              $t("lang.Networkalarm")
            }}</span>
            <span v-if="scope.row.type == 5">{{
              $t("lang.Serverprocessstatusalarm")
            }}</span>
            <span v-if="scope.row.type == 6">{{ $t("lang.CPUalarm") }}</span>
            <span v-if="scope.row.type == 7">{{ $t("lang.Diskalarm") }}</span>
            <span v-if="scope.row.type == 8">{{ $t("lang.Memoryalarm") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.Severitylevel')" align="center">
          <template #default="scope">
            <span v-if="scope.row.warningClass == 1" style="color: #cdb323">{{
              $t("lang.Generalalarm")
            }}</span>
            <span v-if="scope.row.warningClass == 2" style="color: #a936d9">{{
              $t("lang.majoralarm")
            }}</span>
            <span v-if="scope.row.warningClass == 3" style="color: #fd201b">{{
              $t("lang.Emergencyalarm")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.describe')" align="center">
					<template #default="scope">
						<span>{{scope.row.desc |text}}</span>
					</template>
        </el-table-column>
        <el-table-column
          prop="happenTime"
          :label="$t('lang.Sendingtime')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="recoverTime"
          :label="$t('lang.recoverytime')"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center"  v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          popper-class="paging"
          background
         
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 告警设置 -->
    <el-dialog
      :visible.sync="addval"
      width="35%"
      top="2rem"
      :title="$t('lang.alarmmanagement')"
      :show-close="false"
      custom-class="seteqcs_a"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="setma">
        <el-form-item :label="$t('lang.AlarmType') + '：'" prop="type">
          <el-select
            v-model="ruleForm.type"
            :placeholder="$t('lang.Pleaseselectalarmtype')"
            @change="types_a"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="fing == 1">
          <el-form-item :label="$t('lang.describe') + '：'" prop="desc">
            <el-input
              v-model="ruleForm.desc"
              :placeholder="$t('lang.Pleasefillinthealarmdescription')"
              @input="blur"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('lang.Alarmlevels') + '：'"
            prop="warningClass"
          >
            <el-select
              v-model="ruleForm.warningClass"
              :placeholder="$t('lang.Pleaseselectalarmlevel')"
            >
              <el-option
                :label="$t('lang.Generalalarm')"
                :value="1"
              ></el-option>
              <el-option :label="$t('lang.majoralarm')" :value="2"></el-option>
              <el-option
                :label="$t('lang.Emergencyalarm')"
                :value="3"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="fing == 2">
          <el-form-item :label="$t('lang.describe') + '：'" prop="desc">
            <el-input
              v-model="ruleForm.desc"
              :placeholder="$t('lang.Pleasefillinthealarmdescription')"
              @input="blur"
            ></el-input>
          </el-form-item>
          <div class="range">
            <el-form-item :label="$t('lang.Generalalarm') + '：'" required>
              <el-col :span="9">
                <el-form-item prop="soValueScopeMin">
                  <el-input
                    v-model="ruleForm.soValueScopeMin"
                    :placeholder="$t('lang.Lowerthreshold')"
                    @input="blur"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="9">
                <el-form-item prop="soValueScopeMax">
                  <el-input
                    v-model="ruleForm.soValueScopeMax"
                    :placeholder="$t('lang.Upperthreshold')"
                    @input="blur"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <span style="display: inline-block; margin-left: 0.05rem">%</span>
            </el-form-item>
            <el-form-item :label="$t('lang.majoralarm') + '：'" required>
              <el-col :span="9">
                <el-form-item prop="matterValueScopeMin">
                  <el-input
                    v-model="ruleForm.matterValueScopeMin"
                    :placeholder="$t('lang.Lowerthreshold')"
                    @input="blur"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="9">
                <el-form-item prop="matterValueScopeMax">
                  <el-input
                    v-model="ruleForm.matterValueScopeMax"
                    :placeholder="$t('lang.Upperthreshold')"
                    @input="blur"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <span style="display: inline-block; margin-left: 0.05rem">%</span>
            </el-form-item>
            <el-form-item :label="$t('lang.Emergencyalarm') + '：'" required>
              <el-col :span="9">
                <el-form-item prop="urgencyValueScopeMin">
                  <el-input
                    v-model="ruleForm.urgencyValueScopeMin"
                    :placeholder="$t('lang.Lowerthreshold')"
                    @input="blur"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="9">
                <el-form-item prop="urgencyValueScopeMax">
                  <el-input
                    v-model="ruleForm.urgencyValueScopeMax"
                    :placeholder="$t('lang.Upperthreshold')"
                    @input="blur"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <span style="display: inline-block; margin-left: 0.05rem">%</span>
            </el-form-item>
          </div>
          <!-- <el-form-item label='恢复阈值：' prop="recovery">
						<el-input v-model="ruleForm.recovery" placeholder="填写恢复阈值"></el-input>
					</el-form-item> -->
        </div>
      </el-form>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="addval = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine('ruleForm')">
            <p>{{ $t("lang.determine") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as alarms from "@/api/alarm.js";
import * as RegExp from "@/ui-utils/RegExp.js";
export default {
  data() {
    return {
      addval: false,
      value: "",
      value1: "",
      value1_a: "",
      value2: "",
      time: "",
      parsm: {
        page: 1,
        size: 10,
        type: "",
        warningClass: "",
        startTime: "",
        stopTime: "",
        alarmStatus: "",
        nameOrMid: "",
      },
      tableData: [],
      ruleForm: {
        type: "",
        desc: "",
        warningClass: "",
        soValueScopeMin: "",
        soValueScopeMax: "",
        matterValueScopeMin: "",
        matterValueScopeMax: "",
        urgencyValueScopeMin: "",
        urgencyValueScopeMax: "",
      },
      options: [],
      fing: "",
			langs:false,
			addtype:'',
			wangtime:''
    };
  },
	created() {
		if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
			this.langs=true
		}else{
			this.langs=false
		}
	},
	computed:{
		rules(){
			var scopemin = (rule, value, callback) => {
			  if (value === "") {
			    callback(this.$t("lang.Pleaseenterthelowerthreshold"));
			  } else if (!RegExp.integer.test(value) || value > 100) {
			    callback(
			      new Error(this.$t("lang.Pleaseenterapositiveintegerfrom0to100"))
			    );
			  } else {
			    callback();
			  }
			};
			var scopemax = (rule, value, callback) => {
			  if (value === "") {
			    callback(this.$t("lang.Pleaseentertheupperthreshold"));
			  } else if (!RegExp.integer.test(value) || value > 100) {
			    callback(
			      new Error(this.$t("lang.Pleaseenterapositiveintegerfrom0to100"))
			    );
			  } else {
			    callback();
			  }
			};
			return {
				type: [
					{
						required: true,
						message: this.$t("lang.Pleaseselectalarmtype"),
						trigger: "change",
					},
				],
				desc: [
					{
						required: true,
						message: this.$t("lang.Pleasefillinthealarmdescription"),
						trigger: "blur",
					},
				],
				warningClass: [
					{
						required: true,
						message: this.$t("lang.Pleaseselectalarmlevel"),
						trigger: "change",
					},
				],
				soValueScopeMin: [
					{
						required: true,
						validator: scopemin,
						trigger: "blur",
					},
				],
				soValueScopeMax: [
					{
						required: true,
						validator: scopemax,
						trigger: "blur",
					},
				],
				matterValueScopeMin: [
					{
						required: true,
						validator: scopemin,
						trigger: "blur",
					},
				],
				matterValueScopeMax: [
					{
						required: true,
						validator: scopemax,
						trigger: "blur",
					},
				],
				urgencyValueScopeMin: [
					{
						required: true,
						validator: scopemin,
						trigger: "blur",
					},
				],
				urgencyValueScopeMax: [
					{
						required: true,
						validator: scopemax,
						trigger: "blur",
					},
				],
			}
		}
	},
	filters:{
		text(val){
			let zhon = sessionStorage.getItem("language");
			//console.log(zhon)
			let text=''
			if(val){
				let sk=val
				if(zhon=='zhong'){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
				text=sk
			}
			return text
		}
	},
  mounted() {
    this.getList();
    this.gettype();
		this.wangtime=setInterval(()=>{
			if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
				this.langs=true
			}else{
				this.langs=false
			}
			this.options=[]
			this.addtype.forEach((item) => {
				let sk=item.name
				if(this.langs){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
			  this.options.push({ value: item.id, label: sk });
			});
		},2000)
  },
	beforeDestroy() {
		if(this.wangtime){
			clearInterval(this.wangtime)
		}
	},
  methods: {
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取告警类型
    async gettype() {
      this.options = [];
      let res = await alarms.type();
      if (res) {
        this.addtype = JSON.parse(JSON.stringify(res.data));
        res.data=res.data.splice(0, 2);
        res.data.forEach((item) => {
					let sk=item.name
					if(this.langs){
						let wq=sk.split('-')
						sk=wq[0]
					}else{
						let wq=sk.split('-')
						sk=wq[1]
						if(wq.length==1){
							sk=wq[0]
						}
					}
          this.options.push({ value: item.id, label: sk });
        });
      }
    },
    //获取告警列表
    async getList() {
      let res = await alarms.lists(this.parsm);
      if (res.data) {
        this.tableData = res.data;
      } else {
        this.tableData = [];
      }
    },
    //告警类型筛选
    types(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.type = e;
      this.getList();
    },
    //告警级别、状态筛选
    grades(e, index) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (index == 1) {
        this.parsm.warningClass = e;
      } else {
        this.parsm.alarmStatus = e;
      }
      this.getList();
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.startTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.startTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //名称筛选
    search() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.nameOrMid = this.value2;
      this.getList();
    },
    //打开告警设置模态框
    adduser() {
      this.addval = true;
      this.fing = "";
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
        for (let key in this.ruleForm) {
          this.ruleForm[key] = "";
        }
      });
    },
    //刷新输入框
    blur() {
      this.$forceUpdate();
    },
    //告警类型选择
    async types_a(e) {
      if (e > 5) {
        this.fing = 2;
        this.ruleForm.warningClass = "";
      } else {
        this.fing = 1;
        this.ruleForm.soValueScopeMin = "";
        this.ruleForm.soValueScopeMax = "";
        this.ruleForm.matterValueScopeMin = "";
        this.ruleForm.matterValueScopeMax = "";
        this.ruleForm.urgencyValueScopeMin = "";
        this.ruleForm.urgencyValueScopeMax = "";
      }
      let res = await alarms.getType({ type: e });
      if (res.data) {
				let sk=res.data.desc
				if(this.langs){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
        this.ruleForm.desc = sk;
        this.ruleForm.warningClass = res.data.warningClass;
        if (e > 5) {
          this.ruleForm.soValueScopeMin = res.data.soValueScopeMin;
          this.ruleForm.soValueScopeMax = res.data.soValueScopeMax;
          this.ruleForm.matterValueScopeMin = res.data.matterValueScopeMin;
          this.ruleForm.matterValueScopeMax = res.data.matterValueScopeMax;
          this.ruleForm.urgencyValueScopeMin = res.data.urgencyValueScopeMin;
          this.ruleForm.urgencyValueScopeMax = res.data.urgencyValueScopeMax;
        }
        this.$forceUpdate();
      }else{
				this.ruleForm.desc = '';
				this.ruleForm.warningClass = '';
				this.$forceUpdate();
			}
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
    },
    //设置告警
    determine(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alarms.add(this.ruleForm).then((res) => {
            this.$message.success(this.$t("lang.Operations"));
            this.addval = false;
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: space-between;

  .addbtn {
    border: none;
    outline-style: none;
    // width: 126px;
    height: 0.5rem;
    padding: 0 0.15rem;
    background: #307b37;
    border-radius: 0.1rem;
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 0.06rem;
    }
  }
}
/* 模态框 */
/deep/.setma {
  .el-input,
  .el-select {
    width: 2.8rem;
  }
	.el-form-item{
		display: flex;
		align-items: center;
	}
	.el-form-item__label{
		display: inline-block;
		width: 2rem;
	}
  .range {
		.el-form-item__content{
			width: 2.8rem;
		}
    .line {
      text-align: center;
      font-size: 0.18rem;
      color: #333333;
    }
  }
}
.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 0.01rem solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}
.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
</style>
